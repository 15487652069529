// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_k3";
export var caseStudyBackground__bgColor = "bl_k5";
export var caseStudyBackground__lineColor = "bl_k0";
export var caseStudyHead = "bl_kW";
export var caseStudyHead__imageAbsolute = "bl_lc";
export var caseStudyHead__imageWrapper = "bl_kX";
export var caseStudyProjectsSection = "bl_k4";
export var caseStudyQuote__bgRing = "bl_kZ";
export var caseStudyTechSection = "bl_ld";
export var caseStudy__bgDark = "bl_kV";
export var caseStudy__bgLight = "bl_kT";